<template>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content" v-if="is_allow_action">
    <div class="deliverybox-info">
      <b>
        {{ deliverybox.store_address }}
      </b>
      <br />
      <b>
        {{ deliverybox.store_name }}&nbsp;&nbsp;{{
          deliverybox.store_locker_name
        }}</b
      >
    </div>
    <p>
      商品をボックスに入れ、以下の暗証番号をセットしてドアをロックしてください。
    </p>
    <div class="box-key">
      {{ deliverybox.store_locker_key }}
    </div>
    <PaymentActionFlow
      payment_status="done"
      select_status="done"
      post_status="active"
      complete_status="active"
    />
    <div class="btn-wrapper">
      <router-link
        class="button is-rounded is-medium btn-submit"
        :to="{ path: '/payment/store/key-set-confirm', query: $route.query }"
        >次へ</router-link
      >
    </div>
    <p>
      ボックスに入らない商品は発送できません。商品が入るサイズのボックスへ再投函するか、ブラウザを閉じてください。
    </p>
    <p>発送を取りやめる場合は<a class="area-link is-underlined" @click="toReceptionCancel">こちら</a></p>
  </div>
</template>

<script>
import PaymentActionFlow from "../components/elements/PaymentActionFlow.vue"
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"

export default {
  name: "PaymentStoreKeySet",
  components: {
    PaymentActionFlow,
  },
  async beforeMount() {
    this.is_loading = true
    if (this.$route.query.token === undefined) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }
    if (!this.$store.state.deliverybox.store_locker_key) {
      this.is_allow_action = false
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }
    try {
      const reception = await DeliveryboxReceptionRepository.findByToken(this.$route.query.token)
      if (!reception) {
        this.error_message = "事前受付が期限切れとなっています。最初から操作を行ってください。\nまたは、既に発送受付処理が完了している商品の可能性があります。ご確認ください。"
        return
      }
      this.is_allow_action = true
      this.$route.query.token = reception.transaction_token
      this.order_no = reception.order_no
    } catch (error) {
      this.error_message =
        "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
    } finally {
      this.is_loading = false
    }
  },
  data() {
    return {
      is_allow_action: false,
      error_message: "",
      order_no: "",
    }
  },
  computed: {
    deliverybox() {
      return this.$store.state.deliverybox
    },
  },
  methods: {
    toReceptionCancel() {
      this.$router.push({
        path: `/reception/gmo-payment/${this.order_no}/cancel`,
      })
    },
  }
}
</script>

<style scoped>
.deliverybox-info {
  text-align: center;
  padding-bottom: 5px;
}
.box-key {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
.btn-wrapper {
  text-align: center;
  padding: 30px 0 50px;
}
.area-link {
  color: #092c0b;
}
</style>
